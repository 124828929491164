import { EntityBase } from "./entity-base";
import { IRule } from "./models/rule";

export default class Rule extends EntityBase {
    controller: string = 'rule';

    /**
     * Asks the bridge to check for any available updates.
     */
    public getAll = async (scenarioId: string) => (await this.get<IRule[]>(scenarioId));

    public getId = async (scenarioId: string, id: string) => (await this.get<IRule>(`${scenarioId}/${id}`));

    public add = async (scenarioId: string) => (await this.post<IRule>(scenarioId, { }));

    public deleteById = async (scenarioId: string, id: string): Promise<void> => (await this.delete(`${scenarioId}/${id}`));

    public update = async(scenarioId: string, entity: IRule): Promise<void> => (await this.put(entity, scenarioId));
}
