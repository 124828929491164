import { EntityBase } from "./entity-base";
import { IScenario } from "./models/scenario";

export default class Scenario extends EntityBase {
    controller: string = 'scenario';

    /**
     * Asks the bridge to check for any available updates.
     */
    public getAll = async () => (await this.get<IScenario[]>());

    public getId = async (id: string) => (await this.get<IScenario>(id));

    public add = async () => (await this.post<IScenario>(undefined, { }));

    public deleteById = async (id: string): Promise<void> => (await this.delete(id));

    public update = async(entity: IScenario): Promise<void> => (await this.put(entity));

    public setScenarioOrder = async(scenarioIds: string[]): Promise<void> => (await this.post('set-order', scenarioIds));
}
