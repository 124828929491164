
import Api from '@/api/api';
import { store } from '@/store';
import { defineComponent } from 'vue';

const Login = defineComponent({
    components: {
    },
    data() {
        return {
        }
    },
    setup() {
        const error: string | undefined = undefined;
        return {
            username: '',
            password: '',
            error
        }
    },
    methods: {
        login(e: Event) {
            Api.app.authenticate(this.username, this.password).then(authenticationResult => {
                if (authenticationResult?.token == null) {
                    throw new Error('Login failed.');
                } else {
                    store.setAuthentication(this.username, authenticationResult.token, 'User Name');
                }
            }).catch(err => {
                this.error = err?.message;
            })

            // Stop propagation
            e.preventDefault();
            return false;
        }
    }
});
export default Login;
