import App from './entities/app';
import Bridge from './entities/bridge';
import Button from './entities/button';
import Light from './entities/light';
import MotionSensor from './entities/motion-sensor';
import Room from './entities/room';
import Rule from './entities/rule';
import Scenario from './entities/scenario';
import User from './entities/user';
import Value from './entities/value';

export default class Api {
    public static app: App = new App();

    public static bridge: Bridge = new Bridge();

    public static button: Button = new Button();

    public static light: Light = new Light();

    public static motionSensor: MotionSensor = new MotionSensor();

    public static room: Room = new Room();

    public static rule: Rule = new Rule();

    public static scenario: Scenario = new Scenario();
    
    public static value: Value = new Value();

    public static user: User = new User();
}
