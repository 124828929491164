
import { IOpenHomeBridge, store } from "@/store";
import { defineComponent } from "vue";
import Page00Welcome from './page-00-welcome.vue';
import Page01ConnectBridge from './page-01-connect-bridge.vue';
import Page02Complete from './page-02-complete.vue';

const InitialSetup = defineComponent({
    components: {
        Page00Welcome,
        Page01ConnectBridge,
        Page02Complete
    },
    data() {
        return {
            page: 0 as number,
            lastPage: 2 as number,
            connectedBridge: undefined as undefined | IOpenHomeBridge
        }
    },
    methods: {
        previous() {
            this.page = this.page - 1;
            if (this.page < 0) {
                this.page == 0;
            }
        },

        next() {
            this.page = this.page + 1;
            if (this.page > this.lastPage) {
                this.page = this.lastPage;
            }
        },

        isNextEnabled() {
            switch (this.page) {
                case 1:
                    return this.connectedBridge != null;
                default:
                    return true;
            }
        },

        close() {
            // Save the current bridge to the state
            if (this.connectedBridge != null) {
                store.addOpenHomeBridge(this.connectedBridge);
            }
        },
    }
});
export default InitialSetup;
