
import { defineComponent, PropType } from "vue";
import { IOpenHomeBridge } from "@/store";
import { Ping } from "@/api/entities/models/app";
import api from '@/api/api';

const Page01ConnectBridge = defineComponent({
    props: {
        connectedBridge: {
            type: Object as PropType<IOpenHomeBridge>,
            required: false
        },
    },
    emits: ['update:connectedBridge'],
    data() {
        return {
            loading: false as boolean,
            error: undefined as undefined | string,
            address: '10.0.2.12:5000' as string,
            pingResult: undefined as undefined | Ping
        }
    },
    methods: {
        async connect() {
            try {
                this.loading = true;

                this.pingResult = await api.app.ping(this.address);
                if (this.pingResult == null) {
                    // Show error message
                } else {
                    this.$emit('update:connectedBridge', {
                        name: this.pingResult.name,
                        address: this.address
                    } as IOpenHomeBridge);
                }
            } catch (error) {
                this.error = (error as any);
            }

            this.loading = false;
        }
    }
});
export default Page01ConnectBridge;
