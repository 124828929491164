import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b70627e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "initial-setup" }
const _hoisted_2 = { class: "pages" }
const _hoisted_3 = { class: "footer" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_00_welcome = _resolveComponent("page-00-welcome")!
  const _component_page_01_connect_bridge = _resolveComponent("page-01-connect-bridge")!
  const _component_page_02_complete = _resolveComponent("page-02-complete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.page === 0)
        ? (_openBlock(), _createBlock(_component_page_00_welcome, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.page === 1)
        ? (_openBlock(), _createBlock(_component_page_01_connect_bridge, {
            key: 1,
            connectedBridge: _ctx.connectedBridge,
            "onUpdate:connectedBridge": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.connectedBridge) = $event))
          }, null, 8, ["connectedBridge"]))
        : _createCommentVNode("", true),
      (_ctx.page === 2)
        ? (_openBlock(), _createBlock(_component_page_02_complete, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.page > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.previous && _ctx.previous(...args)))
          }, "Previous"))
        : _createCommentVNode("", true),
      (_ctx.page !== _ctx.lastPage)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "primary",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
            disabled: !_ctx.isNextEnabled()
          }, "Next", 8, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.page === _ctx.lastPage)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "primary",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, "Close"))
        : _createCommentVNode("", true)
    ])
  ]))
}