import { StoreBase } from "./store-base";

export interface IOpenHomeBridge {
    name?: string;
    address: string;
}

/**
 * Represents data about the current session.
 */
export interface IBridgeSession {
    name?: string;
    username: string;
    token?: string;
}

export interface IStore extends Object {
    openHomeBridge?: IOpenHomeBridge;
    bridgeSession?: IBridgeSession;
}

class Store extends StoreBase<IStore> {
    constructor() {
        super('app-store');
    }

    protected data(): IStore {
        return {
            openHomeBridge: undefined,
            bridgeSession: undefined
        };
    }

    addOpenHomeBridge(openHomeBridge: IOpenHomeBridge) {
        this.state.openHomeBridge = openHomeBridge;
    }

    /**
     * Sets the authenticated user and session to the bridge.
     * @param username The username for the current session.
     * @param token The token for the current session.
     * @param name The user friendly name of the user in the current session.
     */
    setAuthentication(username: string, token: string, name?: string) {
        this.state.bridgeSession = {
            name,
            username,
            token
        };
    }

    /**
     * Forces the user to login to the OpenHome bridge.
     */
    setLoginRequired() {
        if (this.state.bridgeSession != null) {
            this.state.bridgeSession.token = undefined;
        }
    }
}

export const store: Store = new Store();