import { EntityBase } from "./entity-base";
import { BridgeDescovery, IBridge } from "./models/bridge";

export default class Bridge extends EntityBase {
    controller: string = 'bridge';

    /**
     * Gets all added bridges in OpenHome.
     */
    public getAll = async () => (await this.get<IBridge[]>());

    /**
     * Discovers all available bridges on the network.
     */
    public discover = async () => (await this.get<BridgeDescovery>('discover'));
    
    /**
     * Adds the bridge with the selected ip address (ipv4 or ipv6).
     * @param serviceName The service name of the bridge (type of bridge).
     * @param bridgeIp The IP address to the bridge to add.
     */
    public add = async (serviceName: string, bridgeIp: string) => (await this.put<IBridge>({}, `${serviceName}/${bridgeIp}`));
    
}
