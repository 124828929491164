import { store } from "@/store";
import { HttpStatusCodes } from "./http-status-codes";

export type HttpRequestMethods = 'get' | 'delete' | 'head' | 'options' | 'post' | 'put' | 'patch' | 'purge' | 'link' | 'unlink';

interface InnerException {
    type: string,
    message: string
}

/**
 * Represents a handled error response from the server.
 */
export class ResponseError {
    public code: HttpStatusCodes;
    public type: string;
    public message: string;
    public innerException?: InnerException;
    public retryLater?: number;

    constructor(code: HttpStatusCodes, type: string, message: string, innerException?: InnerException, retryLater?: number) {
        this.code = code;
        this.type = type;
        this.message = message;
        this.innerException = innerException;
        this.retryLater = retryLater;
    }
}

class HttpRequest {
    private currentRequests: Request[] = [];

    /**
     * Sends a HTTP request to the selected address.
     */
    public async sendHttpRequest<TReturn>(method: HttpRequestMethods, url: string, body?: any, headers?: Headers): Promise<TReturn> {
        // Create request
        const request = new Request(url, {
            method,
            headers,
            body: body == null ? undefined : JSON.stringify(body)
        });

        // Send the request
        const response = await fetch(request);

        // Try get the response body value if possible
        const responseValue = await this.handleResponse<TReturn>(response);

        // Check if the response is ok
        if (response.status === HttpStatusCodes.SeeOther) {
            return responseValue as TReturn;
        } else if (!response.ok) {
            const responseError = responseValue as ResponseError | undefined;

            // If the user is not properly logged in,
            // Set the stat to force the user to login again.
            if (response.status == HttpStatusCodes.Unauthorized) {
                store.setLoginRequired();
            }

            // Could not figure out the error, throw a generic error
            if (responseError == null) {
                throw new Error(`Request failed with status code ${response.status}`);
            }

            // Handled errors are thrown as a ResponseError
            throw responseError;
        }

        // The response was handled successfully
        return responseValue;
    }

    /**
     * Handles the response from the server as properly as possible.
     * @param response The response sent from the server to handle.
     * @returns A TReturn object if the response was handled successfully
     */
    private async handleResponse<TReturn>(response: Response): Promise<TReturn> {
        if (response.status === HttpStatusCodes.NoContent) { return undefined as TReturn; }

        try {
            // Try to parse the response
            const responseText  = await response.text();
            if (responseText === null || responseText === '') { return undefined as TReturn; }

            if (!responseText.trimStart().startsWith('{')) {
                return responseText as TReturn; // This is not json, return the response as is
            }

            // Try to parse the response as JSON
            const responseJson = JSON.parse(responseText);
            return responseJson as TReturn;
        } catch(err) {
            // The response could not be handled
            throw new Error(`Could not handle response: ${err}`);
        }
    }
}

const httpRequest = new HttpRequest();
export default httpRequest;