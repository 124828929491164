import { EntityBase } from "./entity-base";
import { ILight } from "./models/light";

export default class Light extends EntityBase {
    controller: string = 'light';

    /**
     * Gets all lights in this home.
     */
    public getAll = async () => (await this.get<ILight[]>());
}
