import { EntityBase } from "./entity-base";
import { IButton } from "./models/button";

export default class Button extends EntityBase {
    controller: string = 'button';

    /**
     * Gets all motion sensors in this home.
     */
    public getAll = async () => (await this.get<IButton[]>());
}
