import { store } from "@/store";
import httpRequest, { HttpRequestMethods } from "./http-request/http-request";

export abstract class EntityBase {
    abstract controller: string;

    protected async get<TEntity>(action?: string, data?: any): Promise<TEntity> {
        // return this.getRaw(this.getBridgeAddress(), this.controller, action, data, this.getHeaders());
        return this.sendHttpRequest<TEntity>('get', this.getBridgeAddress(), this.controller, action, data, this.getHeaders());
    }

    protected async post<TEntity>(action?: string, data?: any): Promise<TEntity> {
        // return this.postRaw<TEntity>(this.getBridgeAddress(), this.controller, action, data, this.getHeaders());
        return this.sendHttpRequest<TEntity>('post', this.getBridgeAddress(), this.controller, action, data, this.getHeaders());
    }

    protected async delete(action?: string, data?: any): Promise<void> {
        // return this.deleteRaw(this.getBridgeAddress(), this.controller, action, data, this.getHeaders());
        return this.sendHttpRequest<void>('delete', this.getBridgeAddress(), this.controller, action, data, this.getHeaders());
    }

    /**
     * Sends a put request (update) to bridge with a entity as update body.
     * @param entity The entity to send as with the put request (the update).
     * @param action Any action to use when sending the put request.
     */
    protected async put<TEntity>(entity?: object, action?: string): Promise<TEntity> {
        return this.sendHttpRequest('put', this.getBridgeAddress(), this.controller, action, entity, this.getHeaders());
    }

    /**
     * Sends a HTTP request to the selected address.
     * @param method The method used for the HTTP request.
     * @param address The endpoint address to send the request to.
     * @param controller Any controller (part of the url) to send the request to.
     * @param action Any controller action (part of the url) to send the request to.
     * @param data Any additional data that will be sent as part of the body.
     * @param headers Any additional headers that will be included in the request.
     */
    protected async sendHttpRequest<TReturn>(
        method: HttpRequestMethods,
        address: string,
        controller?: string,
        action?: string,
        data?: any,
        headers?: any): Promise<TReturn> {
        
        // Create the url
        const actionUrl = action == null ? '' : `/${action}`;
        const controllerUrl = controller == null ? '' : `/${controller}`;
        const url = `${address}${controllerUrl}${actionUrl}`;
        const result = await httpRequest.sendHttpRequest<TReturn>(method, url, data, headers);
        return result;
    }

    /**
     * Gets the address to the current connected OpenHome bridge.
     */
    protected getBridgeAddress() {
        const address = store.getState().openHomeBridge?.address;
        if (address == null) {
            throw new Error('No bridge address available.');
        }

        return `https://${address}`;
    }

    protected getHeaders() {
        return {
            'Authorization': `Bearer ${this.getToken()}`
        };
    }

    protected getToken() {
        const token = store.getState().bridgeSession?.token;
        if (token == null) {
            throw new Error('Cannot send request to bridge. No session token available in store.');
        }

        return token;
    }
}
