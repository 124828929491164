import { EntityBase } from "./entity-base";
import { IValue } from "./models/value";

export default class Value extends EntityBase {
    controller: string = 'value';

    /**
     * Asks the bridge to return all values.
     */
    public getAll = async () => this.get<IValue[]>();

    /**
     * Get value by its name.
     */
    public getByName = async (name: string) => this.get<IValue>(name);

    /**
     * Set value.
     */
    public set = async (value: IValue, id?: string) => this.put<IValue>(value, `${id ?? ''}`);

    /**
     * Delete value.
     */
    public remove = async (id: string) => this.delete(`${id}`);
}
