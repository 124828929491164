import { EntityBase } from "./entity-base";
import { IMotionSensor } from "./models/motion-sensor";

export default class MotionSensor extends EntityBase {
    controller: string = 'motionsensor';

    /**
     * Gets all motion sensors in this home.
     */
    public getAll = async () => (await this.get<IMotionSensor[]>());
}
