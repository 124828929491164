import { EntityBase } from "./entity-base";
import { IRoom } from "./models/room";

export default class Room extends EntityBase {
    controller: string = 'room';

    /**
     * Gets all rooms in this home.
     */
    public getAll = async () => (await this.get<IRoom[]>());
}
