import { store } from "@/store"
import { EntityBase } from "./entity-base"
import { AuthenticationResult, AvailableUpdate, Ping } from "./models/app"

export default class App extends EntityBase {
    controller: string = 'app'

    /**
     * Sends a ping to the selected OpenHome bridge, and returns a summary of information about the bridge.
     */
    public ping = (address: string) => {
        return this.sendHttpRequest<Ping>('get', `https://${address}`, this.controller, 'ping')
    }

    public pingUpdate = (address: string) => this.sendHttpRequest<Ping>('get', this.getBridgeAddress(), address)

    /**
     * Asks the bridge to check for any available updates.
     */
    public checkForUpdates = () => this.get<AvailableUpdate>('updates')

    /**
     * Starts an update to the latest version of OpenHome (if available)
     */
    public startUpdate = async () => (await this.put<string>(undefined, 'update/latest'))

    /**
     * Asks the bridge to check for any available updates.
     */
    public async authenticate(username: string, password: string) {
        const address = store.getState().openHomeBridge?.address
        return (await this.sendHttpRequest<AuthenticationResult>('post', `https://${address}`, this.controller, 'authenticate', {
            name: username,
            password
        }))
    }
}
